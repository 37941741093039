// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import react toastyfy css
@import "~react-toastify/dist/ReactToastify.min.css";
// import 'react-toastify/dist/ReactToastify.css';
//
$primary-color: #0078d4;
@import "~loaders.css/src/animations/line-scale.scss";
// Import Main styles for this application
@import "./scss/style.scss";
// Import custom styles
@import "./views/PadLayout/pad-layout.scss";
@import "./views/ScrollingMenu/scrolling-menu.scss";

//
//
//
@media print {
  @page {
    size: landscape;
  }

  body * {
    visibility: hidden;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }

  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  .tab-pane > section {
    min-height: 1000px !important;
    visibility: visible;
    overflow: visible;
  }
}

// html {
//   scroll-behavior: smooth;
//   // scroll-padding-top: 100px; /* height of sticky header */
// }

// #full-view {
//   scroll-behavior: smooth;
//   scroll-margin-top: 0.5rem !important; /* height of sticky header */
// }

.loader-hidden {
  display: none;
}

.modal-body {
  min-height: 10rem;
}

.modal-body-themed {
  background-color: #3a4149 !important;
}

.loader-active {
  position: absolute;
  top: 33.33%;
  left: calc(50% + 18px);
  // margin-left: calc(50% + 18px);
  transform: scale(2) translate(-50%, -50%);
}

.modal-body .loader-active {
  top: 50%;
  z-index: 999;
}

body {
  font-family: "Nunito", Helvetica, Arial, sans-serif !important;
}

code {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

.justify-content-evenly {
  justify-content: space-evenly!important;
}

.main {
  margin-left: 0px !important;
}

.main .container-fluid {
  padding: 0 15px;
}

.content-container {
  min-height: calc(100vh - 80px) !important;
}

.breadcrumb {
  padding: 0.6rem 1rem;
  margin-bottom: 0px;
  background-color: #2f353a;
}

// ::-webkit-scrollbar {
//     cursor:pointer;
// }

.app-body {
  overflow-x: initial;
}

.app-header {
  background-color: #26519e !important;
  color: whitesmoke;
  // border-top      : 2px solid #2f353a;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

@media (max-width: 991.98px){
  .app-header .navbar-brand {
      position: relative !important;
  }
}
.app-footer {
  flex: 0 0 30px;
  color: whitesmoke;
  background-color: #26519e !important;
  border-top: none;
  // margin-left        : 200px;
  // margin-left        : 0px !important;
}

.app-footer a {
  color: darkgray;
}

.navbar-nav .nav-link {
  color: whitesmoke !important;
  font-size: 1rem;
  cursor: pointer !important;
}

.navbar-nav .nav-link.active
.navbar-nav .active > .nav-link,
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: white !important;
  font-weight: bold;
}

.sidebar .nav-link {
  padding: 0.75rem 1rem 0.75rem 0.75rem;
}

.sidebar .nav-link .nav-icon {
  // display: none;
  width: inherit;
  color: lightslategray;
}

.sidebar .nav-link.active .nav-icon {
  color: whitesmoke;
}

.sidebar .nav-dropdown-items .nav-link {
  padding: 0.75rem 1rem 0.75rem 1.75rem;
}

.sidebar .sidebar-minimizer {
  display: none;
}

.sidebar,
.sidebar .nav,
.sidebar .sidebar-nav {
  width: 160px;
}

.sidebar .nav-item {
  background-color: rgba(0, 0, 0, 0.2);
  text-transform: capitalize;
}

.sidebar .nav-title {
  // background-color: #202124;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.sidebar-ad {
  width: 320px;
  background-color: #2f353a;
}

.sidebar-ad-label {
  display: block;
  text-align: center;
}

.sidebar-ad-300 {
  width: 300px;
  background-color: #2f353a;
}

.sidebar-left-ad {
  width: 160px;
  background-color: #2f353a;
}

.sidebar-ad-img {
  // background: url("/images/Ad_160x600.png") no-repeat center;
  margin: 0px auto auto auto;
  width: 160px;
  height: 600px;
}

.sidebar-left-ad-img {
  // background: url("/images/Ad_160x600.png") no-repeat center;
  // margin: 0px auto auto auto;
  width: 300px;
  height: 250px;
}

.header-ad-container {
  background-color: #2f353a;
}

.footer-ad-container {
  margin-left: -30px;
  margin-right: -30px;
}

.footer-ad {
  display: flex;
}

.footer-ad-img {
  // background: url("/images/Ad_160x600.png") no-repeat center;
  margin: 0px auto;
}

.brand-card {
  border: 3px solid #c8ced3 !important;
  border-radius: 0.5rem !important;
  // cursor       : pointer !important;
}

.row-inner {
  margin-left: -0.25em !important;
  margin-right: -0.25em !important;
}

.col-inner {
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
}

.col-padding-none {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* #region [BUTTONS] */

$color-btn-black: #202124;
$color-btn-white: white;
$color-btn-border: #c8ced3;

.btn-outline-black,
.btn-outline-black:hover {
  color: $color-btn-white;
  background-color: $color-btn-black;
  border-color: $color-btn-border;
  box-shadow: none !important;
  border-radius: 0.25rem 0.25rem 0 0;
}

.btn-outline-white,
.btn-outline-white:hover {
  color: $color-btn-black;
  background-color: $color-btn-white;
  border-color: $color-btn-border;
  box-shadow: none !important;
  border-radius: 0.25rem 0.25rem 0 0;
}

.btn-outline-transparent,
.btn-outline-transparent:hover {
  color: $color-btn-white;
  background-color: transparent;
  background-image: none;
  border-color: $color-btn-border;
  box-shadow: none !important;
  border-radius: 0.25rem 0.25rem 0 0;
}

// .btn-outline-black:hover {
//     color           : white;
//     background-color: #2f353a;
//     border-color    : white;
// }

// .btn-outline-white:hover {
//     color           : #fff;
//     background-color: #2f353a;
//     border-color    : white;
// }

.btn-border-none {
  border: none;
}

/* #endregion */

a:link:not(:has(button.btn)),
a:visited:not(:has(button.btn)),
a:hover:not(:has(button.btn)),
a:active:not(:has(button.btn)) {
  text-decoration: none;
}

// .keybinding {
//     font-family          : Menlo, Monaco, Consolas, 'Courier New', monospace;
//     padding              : 2px 4px;
//     font-size            : 90%;
//     color                : #0072be;
//     background-color     : #f4f2f9;
//     -webkit-border-radius: 4px;
//     -moz-border-radius   : 4px;
//     -o-border-radius     : 4px;
//     border-radius        : 4px;
// }

.site-footer {
  background-color: #26272b;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin: 0;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.lead b{
  font-weight: bold !important;
}

@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

/* #region [SCROLL BAR]*/
/* width */
// ::-webkit-scrollbar {
//     width: 12px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

/* #endregion*/

.brand-card-header {
  height: 5rem !important;
}