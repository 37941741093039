//

#notepadTabContainer .nav-tabs {
    border-bottom: none;
}

#notepadTabContainer .tab-content {
    //border       : 3px solid #26519e;
    margin-top             : -3px;
    border-top-left-radius : 0 !important;
    border-top-right-radius: 0 !important;
}

#notepadTabContainer .nav-tabs a {
    display: flex;
}

#notepadTabContainer .tab-text-container {
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
}

#notepadTabContainer .tab-pane textarea {
    font-family     : Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    color           : black;
    background-color: transparent;
    border          : none;
    outline         : none;
    box-shadow      : none;
}

#notepadTabContainer .tab-pane textarea:focus {
    font-family     : Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    color           : black;
    background-color: transparent;
    border          : none;
    outline         : none;
    box-shadow      : none;
}

#notepadTabContainer .tab-content .tab-pane {
    padding   : 0px 0px 1px 0px;
    min-height: calc(100vh - 180px) !important;
}

#notepadTabContainer .tab-btn {
    background-color: #202124;
}

#notepadTabContainer .tox.tox-tinymce {
    min-height: calc(100vh - 90px) !important;
}

// #notepadTabContainer .monacoEditorStyle {
//     height    : 100% !important;
//     min-height: calc(100vh - 175px) !important;
// }

.Toastify__toast--info {
    background: #0078d4;
}

.main-buttons .btn,
.main-buttons .btn:active,
.main-buttons .btn:focus {
    border    : none;
    outline   : none !important;
    box-shadow: none !important;
}

.main-buttons .dropdown-menu,
.main-buttons .dropdown-items {
    border          : 1px solid #005ba1;
    background-color: #0078d4;
    margin-top      : 1px;
    // min-height      : 100px;
    max-height      : calc(100vh - 200px) !important;
}

.main-buttons .dropdown-item {
    padding         : 5px 20px;
    color           : #fff;
    background-color: #0078d4;
    border-color    : #005ba1;
}

.main-buttons .dropdown-item:hover {
    color           : #fff;
    background-color: #005ba1;
    border-color    : #005494;
}

.main-buttons .dropdown-item.active {
    color           : #fff;
    background-color: #005ba1;
    border-color    : #005494;
}

.main-buttons .dropdown-toggle {
    overflow     : hidden !important;
    text-overflow: ellipsis !important;
    white-space  : nowrap !important;
}

.main-buttons .dropdown-toggle::after {
    float     : right;
    margin-top: 0.5rem;
}

.main-buttons .btn-group .btn-group-inner > button {
    text-align: left;
    padding-left: 1.5em;
}

.btn-quick-options,
.btn-quick-options:focus {
    width: 36px;    
    border: 1.5px solid whitesmoke !important;
}

.dropdown-items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color  : #0078d4;
}

.dropdown-items::-webkit-scrollbar {
    width           : 10px;
    border-radius   : 10px;
    background-color: #005494;
}

.dropdown-items::-webkit-scrollbar-thumb {
    border-radius   : 10px;
    background-color: #005494;
}

#btnSettings:before {
    content: "\f1de";
    margin-right: 0.5rem;
    font: normal normal normal 14px/1 FontAwesome;
}

.react-switch {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.loader-dark {
    background-color: #202124;
}

.editor-footer {
    // position: absolute;
    bottom: 0;
    height: 30px;
    line-height: 29px;
    width: 100%;
    font-size: .875em;
    // background: #f0f0f0;
    background: #f0f3f5;
    border-top: 1px solid #c8ced3;
    padding: 0 1rem;
}

.keybinding {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    padding: 2px 4px;
    font-size: 90%;
    color: #0072be;
    background-color: #f4f2f9;
    border-radius: 4px;
}

.back-to-editor:before {
    content: "\21E6";
    padding-right: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
}

// Google Ads sizes
#ggle-adsns-normal-728x90 {
    width: 728px !important;
    height: 90px !important;
}

#ggle-adsns-normal-300x250-1 {
    width: 300px !important;
    height: 250px !important;
}

#ggle-adsns-normal-300x250-2 {
    width: 300px !important;
    height: 250px !important;
}

#ggle-adsns-normal-320x100 {
    width: 320px !important;
    height: 100px !important;
}

#ggle-adsns-wide-300x250 {
    width: 300px !important;
    height: 250px !important;
}

#ggle-adsns-wide-728x90-1 {
    width: 728px !important;
    height: 90px !important;
}

#ggle-adsns-wide-728x90-2 {
    width: 728px !important;
    height: 90px !important;
}

#ggle-adsns-wide-728x90-3 {
    width: 728px !important;
    height: 90px !important;
}

#ggle-adsns-wide-320x100 {
    width: 320px !important;
    height: 100px !important;
}
