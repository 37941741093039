.arrow-prev,
.arrow-next {
    border       : 1.5px solid whitesmoke;
    font-weight  : 900;
    font-size    : x-large;
    padding-left : 10px;
    padding-right: 10px;
    cursor       : pointer;
    margin-bottom: -3px;
}

.arrow-prev:hover,
.arrow-next:hover {
    border: 1.5px solid whitesmoke;
}

.arrow-prev {
    border-top-left-radius: .25rem;
}

.arrow-next {
    border-top-right-radius: .25rem;
}

.menu-wrapper {
    background-color: #393939;
    border-bottom   : 1px solid whitesmoke;
    width           : 100%;
}